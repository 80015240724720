import React from "react"
import { css } from "linaria"
import { styled } from "linaria/react"

export const ResettedButton = styled.button`
  outline: none;
  border: none;
`

export const flexCenterXY = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}
