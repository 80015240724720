import React, { useReducer, useRef, useCallback, useEffect } from "react"
import { Link } from "gatsby"
import { css } from "linaria"
import { styled } from "linaria/react"
import {
  FaToggleOn,
  FaToggleOff,
  FaPlayCircle,
  FaPauseCircle,
} from "react-icons/fa"
import YouTube from "react-youtube"
import { ResettedButton, flexCenterXY } from "./Shared"

export const Styled = do {
  const Row = styled.div`
    font-family: "Noto Sans TC", "Open Sans", sans-serif;
    border-bottom: 1px solid #aaa;
    padding: 5px 0;
    display: flex;
    align-items: center;
  `
  const wordItem = {
    flex: "1",
    wordBreak: "break-all",
  }
  const playItem = {
    minWidth: "90px",
    flex: "0 1",
    margin: "0 10px",
  }
  const iframeItem = {
    flex: "0 1",
    overflow: "hidden",

    "@media screen and (max-width: 590px)": {
      minWidth: "100px",
      margin: "0 5px",
    },
    "@media screen and (min-width: 600px)": {
      minWidth: "200px",
      margin: "0 10px",
    },
  }

  const SentenceHeader = styled.div`
    ${wordItem}
  `
  const PlayHeader = styled.div`
    ${playItem}
  `
  const IframeItem = styled.div`
    ${iframeItem}
  `
  const PlayButton = styled(ResettedButton)`
    ${playItem}
    ${flexCenterXY}
    line-height: 40px;
    background-color: #ccc;
    border-radius: 3px;
  `

  const playIcon = css`
    margin-right: 5px;
    color: red;
    font-size: 30px;
  `

  const youtubeIframe = css`
    margin: 0;
  `

  ;({
    SentenceHeader,
    PlayHeader,
    IframeItem,
    PlayButton,
    playIcon,
    youtubeIframe,
    Row,
  })
}

function convertTimeAtToSecond(timeAt) {
  return timeAt
    .toFixed(2)
    .split(".")
    .reverse()
    .reduce((acc, it, index) => {
      return acc + parseInt(it, 10) * Math.pow(60, index)
    }, 0)
}

function REDUCER(state, { type, payload }) {
  switch (type) {
    case "ON_CLICK": {
      return {
        ...state,
        isOpened: true,
      }
    }
    case "ON_STATE_CHANGE": {
      return {
        ...state,
        playerState: payload.playerState,
      }
    }
  }
}

const ALL_PLAYER_LIST = []

function pauseOther(player) {
  ALL_PLAYER_LIST.forEach(it => {
    if (player !== it) {
      it.pauseVideo()
    }
  })
}

const INITIAL_STATE = {
  isOpened: false,
  playerState: undefined,
}

export default function CustomizedYouTube({
  isMandarinEnabled,
  isJyutpingEnabled,
  data: { mandarin, cantonese, startTimeAt, endTimeAt, opts, ...restProps },
  jyutping,
}) {
  const startSec = convertTimeAtToSecond(startTimeAt)
  const endSec = convertTimeAtToSecond(endTimeAt)
  const [state, dispatch] = useReducer(REDUCER, INITIAL_STATE)
  const playerRef = useRef()
  const onClick = useCallback(() => {
    dispatch({ type: "ON_CLICK" })
    const { current: player } = playerRef
    if (!player) {
      return
    }
    if (state.playerState === YouTube.PlayerState.PLAYING) {
      player.pauseVideo()
    } else {
      pauseOther(player)
      player.playVideo()
    }
  }, [state, dispatch])
  const onReady = useCallback(
    ({ target: player }) => {
      ALL_PLAYER_LIST.push(player)
      playerRef.current = player
      player.seekTo(startSec)
      pauseOther(player)
    },
    [startSec]
  )
  const onStateChange = useCallback(
    ({ target: player }) => {
      dispatch({
        type: "ON_STATE_CHANGE",
        payload: { playerState: player.getPlayerState() },
      })
    },
    [dispatch]
  )
  useEffect(() => {
    setInterval(() => {
      const { current: player } = playerRef
      if (!player) {
        return
      }
      if (player.getCurrentTime() >= endSec) {
        player.pauseVideo()
        player.seekTo(startSec)
      }
    }, 500)

    return () => {
      const { current: player } = playerRef
      if (!player) {
        return
      }
      playerRef.current = undefined
      ALL_PLAYER_LIST.splice(ALL_PLAYER_LIST.indexOf(player), 1)
    }
  }, [restProps.videoId])

  return (
    <Styled.Row>
      {isMandarinEnabled && (
        <Styled.SentenceHeader>{mandarin}</Styled.SentenceHeader>
      )}
      <Styled.SentenceHeader>
        {(isJyutpingEnabled && jyutping) || cantonese}
      </Styled.SentenceHeader>
      <Styled.PlayButton onClick={onClick}>
        {state.playerState === YouTube.PlayerState.PLAYING && (
          <>
            <FaPauseCircle className={Styled.playIcon} />
            暫停
          </>
        )}
        {state.playerState !== YouTube.PlayerState.PLAYING && (
          <>
            <FaPlayCircle className={Styled.playIcon} />
            播放
          </>
        )}
      </Styled.PlayButton>
      <Styled.IframeItem>
        {state.isOpened && (
          <YouTube
            {...restProps}
            opts={{
              height: "100",
              width: "200",
              playerVars: {
                /**
                 * @url https://developers.google.com/youtube/player_parameters
                 */
                controls: 0,
              },
            }}
            containerClassName={Styled.youtubeIframeContainer}
            className={Styled.youtubeIframe}
            onReady={onReady}
            onStateChange={onStateChange}
          />
        )}
      </Styled.IframeItem>
    </Styled.Row>
  )
}
