import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TableWithControl from "../components/TableWithControl"

export default function() {
  return (
    <Layout>
      <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
      <h1>學學廣東話</h1>
      <p>一起聽聽發音吧！</p>
      <TableWithControl />
    </Layout>
  )
}
