import React, { useReducer, useCallback, useEffect, useRef } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { css } from "linaria"
import { styled } from "linaria/react"
import { FaToggleOn, FaToggleOff } from "react-icons/fa"
import CustomizedYouTube, { Styled as TableStyled } from "./CustomizedYouTube"
import { ResettedButton, flexCenterXY } from "./Shared"

export const Styled = do {
  const Controls = styled.div`
    background-color: #efefef;
    padding: 20px;
  `

  const ControlGroup = styled.div`
    display: flex;
  `

  const ToggleButton = styled(ResettedButton)`
    ${flexCenterXY}
    margin-left: 5px;
    border-radius: 3px;
    line-height: 40px;
  `

  ;({
    Controls,
    ControlGroup,
    ToggleButton,
  })
}

function REDUCER(state, { type, payload }) {
  switch (type) {
    case "INIT": {
      return {
        ...state,
        ...JSON.parse(payload),
      }
    }
    case "ON_MANDARIN_CLICK": {
      return {
        ...state,
        isMandarinEnabled: !state.isMandarinEnabled,
      }
    }
    case "ON_PY_CLICK": {
      return {
        ...state,
        isJyutpingEnabled: !state.isJyutpingEnabled,
      }
    }
  }
}

const INITIAL_STATE = {
  isMandarinEnabled: false,
  isJyutpingEnabled: true,
}

export default function TableWithControl() {
  const [state, dispatch] = useReducer(REDUCER, INITIAL_STATE, state => {
    try {
      return {
        ...state,
        ...JSON.parse(window.localStorage.getItem("TableWithControl")),
      }
    } catch {
      return state
    }
  })
  useEffect(() => {
    window.localStorage.setItem("TableWithControl", JSON.stringify(state))
  }, [state])
  const onMandarinClick = useCallback(() => {
    dispatch({ type: "ON_MANDARIN_CLICK" })
  }, [dispatch])
  const onPYClick = useCallback(() => {
    dispatch({ type: "ON_PY_CLICK" })
  }, [dispatch])

  const {
    gcms: { segments },
    allHongkongvision: { nodes },
  } = useStaticQuery(graphql`
    {
      gcms {
        segments(orderBy: createdAt_DESC) {
          id
          videoId
          cantonese
          mandarin
          startTimeAt
          endTimeAt
        }
      }
      allHongkongvision {
        nodes {
          id
          jyutping
        }
      }
    }
  `)
  const jyutpingBy = nodes.reduce((acc, { id, jyutping }) => {
    acc[id] = jyutping
    return acc
  }, {})

  return (
    <>
      <Styled.Controls>
        <Styled.ControlGroup>
          顯示北京話
          <Styled.ToggleButton onClick={onMandarinClick}>
            {state.isMandarinEnabled ? <FaToggleOn /> : <FaToggleOff />}
          </Styled.ToggleButton>
        </Styled.ControlGroup>
        <Styled.ControlGroup>
          顯示粵拼
          <Styled.ToggleButton onClick={onPYClick}>
            {state.isJyutpingEnabled ? <FaToggleOn /> : <FaToggleOff />}
          </Styled.ToggleButton>
        </Styled.ControlGroup>
      </Styled.Controls>
      <TableStyled.Row>
        {state.isMandarinEnabled && (
          <TableStyled.SentenceHeader>北京話</TableStyled.SentenceHeader>
        )}
        <TableStyled.SentenceHeader>
          廣東話
          {state.isJyutpingEnabled && "[粵拼]"}
        </TableStyled.SentenceHeader>
        <TableStyled.PlayHeader>發音</TableStyled.PlayHeader>
        <TableStyled.IframeItem>影片</TableStyled.IframeItem>
      </TableStyled.Row>
      {segments.map(it => (
        <CustomizedYouTube
          key={it.id}
          isMandarinEnabled={state.isMandarinEnabled}
          isJyutpingEnabled={state.isJyutpingEnabled}
          data={it}
          jyutping={jyutpingBy[it.id]}
        />
      ))}
      <ul>
        {[
          "https://hongkongvision.com/tool/cc_py_conv_zh",
          "https://www.chineseconverter.com/cantonesetools/zh-tw/cantonese-text-to-sound",
        ].map(it => (
          <li key={it}>
            <a href={it} target="_blank">
              {it}
            </a>
          </li>
        ))}
      </ul>
    </>
  )
}
